import * as React from 'react';
import Loading from '@components/Loading';
import { useEffect, useState } from 'react';
import { useTranslation } from '@external/react-i18next';
import { Drupal } from '@typings/drupal';
import useAxios from 'axios-hooks';
import { useNotifications } from '@use-cases/notifications';
import queryString from 'query-string';
import { log } from '@hooks/logger';
import { LogLevel } from '@typings/graphql';
import { useBasicInfo } from '@repositories/basicInfo';
import { useFetchOnlineIdEmail } from '@hooks/useFetchOnlineIdEmail';
import { DIS } from '@typings/dis';
import { FEATURE_SHAREPOINT, isEnabled } from '@utils/features';

interface ImpersonationQueryParams {
  impersonatorID?: string | null;
  individualID?: string | null;
  destination?: string | null;
}

const useWindowParams = () => {
  const [params, setParams] = useState<ImpersonationQueryParams>({});

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.search) {
      const queryParams = queryString.parse(window.location.search);
      setParams(queryParams);
    }
  }, []);

  return [params];
};

const ImpersonationAuthPage: React.FC = () => {
  const { t } = useTranslation();
  const [params] = useWindowParams();
  const { addError } = useNotifications();
  let userInfo = null;
  const [fetchbasicInfo, { loading: fetchbasicInfoLoading }] = useBasicInfo();
  const [
    fetchOnlineEmailId,
    { data: fetchOnlineEmailIdData, loading: fetchOnlineEmailIdLoading },
  ] = useFetchOnlineIdEmail();
  const isSharepointEnabled = isEnabled(FEATURE_SHAREPOINT);

  const [{ data, error: impersonationError, loading }, execute] = useAxios<
    Drupal.ImpersonationAuthOutput
  >(
    {
      url: `${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/impersonate?impersonatorID=${params?.impersonatorID}&individualID=${params?.individualID}`,
      withCredentials: true,
    },
    { manual: true }
  );

  const errorMessage = t(
    'user.login.failMessage',
    'We could not log you into our backend app.'
  );
  useEffect(() => {
    if ('impersonatorID' in params && 'individualID' in params) {
      execute().catch(() => addError(errorMessage));
      if (isSharepointEnabled) {
        fetchOnlineEmailId({
          variables: {
            individualId: params.individualID as string,
          },
        }).catch(() => addError(errorMessage));
      }
    }
  }, [params, isSharepointEnabled]);

  useEffect(() => {
    if (fetchOnlineEmailIdData?.getOnlineIdEmail) {
      fetchbasicInfo({
        variables: {
          emailId: fetchOnlineEmailIdData.getOnlineIdEmail as string,
        },
      })
        .then(async basicUserInfo => {
          userInfo = (basicUserInfo?.data
            ?.basicInfo as unknown) as DIS.SSOTicketResponse;
          log({
            level: LogLevel.Debug,
            message: `Drupal: impersonation-auth, userInfo`,
            other: {
              userInfo,
            },
          });
        })
        .catch(() => addError(errorMessage));
    }
  }, [fetchOnlineEmailIdData]);

  if (loading || fetchOnlineEmailIdLoading || fetchbasicInfoLoading) {
    return <Loading />;
  }

  if (!impersonationError && data && data?.code.toString() === '200') {
    log({
      level: LogLevel.Debug,
      message: `Drupal: impersonation-auth, request to ${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/impersonate?impersonatorID=${params?.impersonatorID}&individualID=${params?.individualID}`,
      other: {
        requestData: {
          withCredentials: true,
          manual: true,
        },
        response: data,
      },
    });

    window.location.assign(
      (params?.destination && params?.destination.toString()) || '/'
    );
  }

  return <Loading />;
};

export default ImpersonationAuthPage;
